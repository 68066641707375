import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"

class Design extends PageComponent {

    src = "/images/service/proektirovanie-sistem-ventilyacii.png"

    priceList = [
        {
            name: 'до 100м2',
            price: '10800 руб'
        },
        {
            name: 'от 100 до 200м2',
            price: '21600 руб'
        },
        {
            name: ' 200-699 м2',
            price: '81 руб/м2'
        }, {
            name: ' от 700 м2',
            price: 'дог.'
        }
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость проектирования вентиляции' list={this.priceList}/>
                <Consultation/>
                <Content>
                    <h2>Проектирование систем вентиляции</h2>
                    <p>
                        Воздухообмен – важный производственный фактор, влияющий на результат любого технологического
                        процесса. Он оказывает воздействие и на жизнедеятельность живых организмов. Чтобы это понять,
                        достаточно представить, что вы находитесь в плотно закрытой банке. Сколько вы просидите в таких
                        условиях, и к чему это приведет, не нужно объяснять.
                        Требуется вентилирование – принудительное проветривание помещений и емкостей. Первым этапом на
                        пути организации этого процесса является разработка проекта соответствующей инженерной системы.
                    </p>
                    <h3>Зачем нужно проектирование вентиляции?</h3>
                    <p>
                        В жизнедеятельности и производстве большое значение имеют не только наличие воздуха, но
                        и его состав, пропорции составляющих, скорость движения воздушных масс. Влияние вредных
                        производственных факторов прямо пропорционально герметичности помещений.
                        Свежий воздух не может поступить в помещение, если загрязненный из него не удален.
                        Значит, воздушные массы должны не только поступать, но и выводиться наружу. Открыть окно
                        недостаточно, а в холодное время года даже опасно. Поэтому требуется проектирование
                        вентиляции – оно заключается в том, что специалисты проводят инженерный расчет с целью
                        достижения ее работоспособности и экономичности. Не требует проектирования вентиляция
                        естественная – в ней движение воздуха происходит за счет щелей, окон и дверей, а эффект
                        от нее редко бывает достаточным.
                    </p>
                    <h2>Проектирование промышленной вентиляции</h2>
                    <p>
                        Воздухообмен на производстве и промышленных объектах намного серьезнее, чем в жилых.
                        Причина этого кроется во вредных производственных факторах, набор которых зависит от
                        отрасли. Это:
                    </p>
                    <ul>
                        <li>Пыль – минеральная, древесная, животного происхождения</li>
                        <li>Водяной пар</li>
                        <li>Дым, гарь</li>
                        <li>Запах, газы</li>
                        <li>Низкая концентрация кислорода</li>
                        <li>Биологические микроорганизмы – бактерии, вирусы, грибки.</li>
                    </ul>
                    <p>
                        Естественное движение воздуха здесь не поможет, поэтому требуется приточно-вытяжная
                        вентиляция. Универсального подхода для ее устройства нет: проект для завода требует
                        исходить из воздухопотребления машин и механизмов. Проект для организаций по
                        обслуживанию населения (кафе, ресторанов, кинотеатров) основан на расчете количества
                        посетителей. Обязательно учитываются объемы помещений, концентрация и природа вредных
                        компонентов в воздухе, а эстетике уделяется второстепенное внимание.
                    </p>

                    <h3>Проектирование вентиляции в частных домах и квартирах</h3>
                    <p> В квартире и жилом доме разработать приточно-вытяжную систему не легче, чем для
                        промышленного объекта. Естественное движение воздушных масс в частном доме и квартире
                        затруднено, если они утеплены. Полагаться на окна нельзя, т.к. проветривание ведет к
                        теплопотерям, шуму, пыли и насекомым с улицы. Вредных факторов на жилых площадях меньше,
                        но эстетической составляющей уделяется большое внимание. Малые габариты и дизайн жилых
                        помещений требуют особого подхода для монтажа воздуховодов и вентиляторов. Их приходится
                        вписывать в интерьер, маскируя или декорируя.
                    </p>
                    <p>Вентиляция жилых помещений имеет свои особенности:</p>
                    <ul>
                        <li>Свежий воздух поступает через спальню, кухню, гостиную</li>
                        <li>Вытяжка происходит из кухни, коридоров, ванной комнаты и санузла.</li>
                        <li>Приточная вентиляция снабжается фильтрами и нагревателями.
                        </li>
                        <li>Приточно-вытяжные установки снабжаются рекуператорами, возвращающими тепло в
                            помещение.</li>
                        <li>Проектирование вентиляции на объектах жилой недвижимости желательно планировать на
                            этапе ремонта.
                        </li>
                    </ul>
                    <h3>Наши предложения</h3>
                    <p> Независимо от того, к какому классу относится ваш объект, мы с одинаковым
                        профессионализмом подходим к проектированию вентиляции в общественных, производственных
                        и жилых помещениях. Мы решим задачи по интегрированию вентиляции в прочие инженерные
                        системы (кондиционирования, увлажнения воздуха), согласуем работы с другими
                        специалистами (строителями, дизайнерами) для того, чтобы новая инженерная система не
                        нарушала общий замысел относительно назначения, эргономики и эстетики помещений.
                        Стоимость работ за м2 проекта у нашей компании ниже, чем у конкурентов. Гарантийное
                        обслуживание, учтивое отношение к заказчику – еще одни преимущества работы с нами.
                    </p>
                </Content>
            </Container>
        )
    }
}

export default PageLayout(Design);
