import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"
import ImagesBlock from "../../UI/ImagesBlock"

class ConditionerClean extends PageComponent {

    priceList = [
        {
            name: 'в зависимости от сложности',
            price: 'от 8000 руб'
        },
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость монтажа VRF/VRV системы' list={this.priceList}/>
                <div className="works__link">
                    <a href='#works'>Примеры наших работ&nbsp;<i className="fa fa-link fa-sm"/></a>
                </div>
                <Consultation/>
                <Content>
                    <h2>VRF/VRV системы кондиционирования: монтаж и ремонт</h2>
                    <p>
                        Большинство бытовых кондиционеров и сплит систем имеют ограниченные возможности по мощности и
                        монтажу из-за небольшой длины межблочных коммуникаций, которая продиктована техническими
                        требованиями по установке. Это приводит к большому числу наружных блоков на фасаде здания,
                        портящих его архитектурный облик и требующих строгих согласований с местными властями. Множество
                        магистралей от нескольких наружных блоков портит интерьер.
                    </p>
                    <p>
                        В Москве эта проблема актуальна вследствие наличия исторических построек, занимаемых
                        апартаментами, общественными и коммерческими учреждениями.
                    </p>
                    <p>
                        40 лет назад было найдено решение проблемы – центральные VRF/VRV-системы кондиционирования с
                        переменным расходом хладагента, позволявшие установить разную температуру в помещениях при одном
                        наружном и нескольких внутренних блоках. Они допускают использование длинной магистрали, точную
                        регулировку температуры воздуха в каждой зоне по отдельности, и не так портят фасад и интерьер.
                        Монтаж VRV систем открывает новые возможности климатической техники, но требует особого подхода.
                    </p>

                    <h3>В чем преимущества установки мультизональных кондиционеров?</h3>
                    <p>
                        VRF и VRV кондиционеры принципиально друг от друга не отличаются. Разница только в названии,
                        которые разные производители дают своей технике. Перед обычными сплит-системами они имеют ряд
                        преимуществ, о которых было сказано выше. Установка таких кондиционеров позволяет:
                    </p>
                    <ul>
                        <li>разместить внешние блоки далеко за пределами фасада: на крыше, на земле, технических
                            платформах, в подвале
                        </li>
                        <li>проложить труботрассу под землей, запланировать ее на этапе строительства здания</li>
                        <li>создать разветвленную и протяженную магистраль, способную обеспечить одновременную работу 20
                            – 350 (!) внутренних блоков от одного наружного
                        </li>
                        <li>использовать гибкие настройки системы</li>
                    </ul>
                    <p>
                        Подобная техника обладает поразительной надежностью и функциональностью. Неспроста монтаж VRV
                        систем выгоден в больших частных домовладениях, общественных и коммерческих заведениях и на
                        промышленных предприятиях. У них имеется лишь два недостатка: высокий вес внешнего блока (около
                        полутонны), и большая стоимость.
                    </p>
                    <p>
                        Доверять установку VRV/VRF систем следует только тем, кто располагает необходимым парком
                        спецтехники для манипуляций с внешними блоками при разных способах монтажа. Ни одна бригада не
                        сможет поднять их вручную на высоту. Также требуется расчет нагрузки на несущую конструкцию
                        здания. Доверившись в этом деле дилетантам, вы рискуете потерять дорогое оборудование или
                        спровоцировать аварию.
                    </p>

                    <p>
                        VRV/VRF техника очень надежна, и причины ее поломок часто обусловлены лишь неправильным
                        монтажом. Большую роль играет специальный инструмент для сборки и опрессовки фреонового контура,
                        вакуумирования и заправки хладагента. Есть свои особенности пусконаладки, результаты которой
                        также сказываются на долговечности и эффективности работы кондиционеров. Чтобы не навредить
                        зданию, в случае кровельного монтажа VRV систем требуется устройство дренажной канавки, иначе
                        вода будет скапливаться на крыше, что зимой приведет к обледенению.
                    </p>
                    <h3>Обслуживание ВРВ систем</h3>
                    <p>Особого сервиса эти системы не требуют. Но ремонт ВРВ может понадобиться тем быстрее, чем меньше
                        опыта было у установщиков. Даже к неисправному кондиционеру нельзя подпускать случайных
                        исполнителей, т.к. цены на подобное оборудование очень велики.
                        Выполнить качественный монтаж, диагностику и ремонт VRV/VRF систем может только опытный и хорошо
                        оснащенный исполнитель. Поручить эти работы лучше нашей компании, т.к. мы специализируемся на
                        выполнении заказов по монтажу и обслуживанию не только бытовых, но и промышленных
                        кондиционеров. </p>
                </Content>
                <ImagesBlock images={
                    ['/vrv/1.jpeg', '/vrv/2.jpeg', '/vrv/3.jpeg', '/vrv/4.jpeg']
                } alt='VRF/VRV системы кондиционирования: монтаж и ремонт'/>
            </Container>
        )
    }
}

export default PageLayout(ConditionerClean);
