import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"
import ImagesBlock from "../../UI/ImagesBlock"

class VentilationInstall extends PageComponent {

    priceList = [
        {
            name: 'до 1000м3',
            price: '12900 руб'
        },
        {
            name: 'до 300м3',
            price: '19900 руб'
        },
        {
            name: ' от 300м3',
            price: 'дог.'
        }
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость монтажных работ' list={this.priceList}/>
                <div className="works__link">
                    <a href='#works'>Примеры наших работ&nbsp;<i className="fa fa-link fa-sm"/></a>
                </div>
                <Consultation/>
                <Content>
                    <h2>Установка и монтаж систем вентиляции</h2>
                    <p>
                        Каждое помещение требует вентиляции, которая в своем специфическом варианте существует даже на
                        космических кораблях и подводных лодках, где отсутствуют возможности для естественного
                        воздухообмена. Здания, жилые и производственные помещения тем более нуждаются в ней.
                        Если расчет естественной вентиляции показывает, что она недостаточна, требуется проектирование
                        системы принудительного воздухообмена. Когда проект готов, остается приступить за его
                        реализацию.
                        Здесь мы расскажем об особенностях этой работы на объектах жилых и производственных.
                    </p>

                    <h3>Промышленная вентиляция: установка и монтаж</h3>
                    <p>
                        На заводах и фабриках монтажные работы не требуют особого внимания к эстетической составляющей
                        процесса. Главное здесь – чтобы элементы оборудования были эффективными по содержанию и
                        аккуратными на вид. Если есть возможность укрыть их от глаз – эта возможность должна быть
                        использована.
                    </p>
                    <p>
                        Когда закуплены необходимые по проекту комплектующие, начинаются монтажные работы, состоящие из
                        следующих этапов:
                    </p>
                    <ul>
                        <li>Подготовки помещений: укрепления несущих конструкций, установки вспомогательного
                            освещения, прокладки переносных источников тока
                        </li>
                        <li>Монтажа вибропоглощающих площадок для нейтрализации шума и вибраций</li>
                        <li>Установки вентиляционного оборудования – промышленных вентиляторов, работающих на
                            вытяжку и приток
                        </li>
                        <li>Монтажа воздуховодов</li>
                        <li>Установки системы автоматики</li>
                        <li>Испытательный пуск – проверка работоспособности оборудования на соответствие параметрам,
                            заложенным в законодательных нормативах и проекте.
                        </li>
                        <li>Установки фурнитуры – решеток, фильтров, диффузоров, противодымных вытяжек, калориферов
                            и т.д.
                        </li>
                    </ul>
                    <p>
                        При этом придерживаются следующих особенностей работы с промышленными объектами:
                    </p>
                    <ul>
                        <li>Когда монтируется моноблочная система, или есть возможность группировки вентиляторов, это
                            оборудование располагается в отдельном помещении – вентиляционной камере
                        </li>
                        <li>Массивное оборудование устанавливается на жестких и несущих конструкциях. Если это
                            невозможно, применяется компенсирующая или распределяющая нагрузку арматура. Крепление
                            элементов системы никогда не производится на конструкциях, склонных к вибрациям.
                        </li>
                    </ul>

                    <h3>Монтаж вентиляции в частных домах и квартирах</h3>
                    <p>
                        Несмотря на то, что промышленные объекты требуют установки самых серьезных и мощных систем, на
                        объектах жилой недвижимости острее стоит вопрос об эстетике оборудования для воздухообмена.
                        В этой связи монтаж вентиляции в домах и квартирах желательно проводить во время ремонта или до
                        его начала. Традиционно в жилых зданиях нет отдельных помещений для установки вентилирующих
                        устройств, поэтому вытяжные вентиляторы монтируются в кухне и других вспомогательных помещениях:
                        ванной, коридоре, санузле. Приточные вентиляторы устанавливаются в жилых помещениях – спальнях,
                        кабинетах, гостиных и кухне.
                    </p>

                    <h3>Где можно заказать монтаж вентиляции?</h3>
                    <p>
                        Услуга по монтажу вентиляции на всех объектах недвижимости в Москве доступна каждому, кто
                        обратится к нам. Наши мастера обладают высокой квалификацией, специальной подготовкой и большим
                        опытом работы с производственными, общественными и жилыми зданиями. В любом случае
                        вентиляционная система, установленная нами, будет функциональной, эффективной и недорогой. В
                        пределах гарантийного срока осуществляется ее бесплатный ремонт и обслуживание.
                    </p>
                </Content>
                <ImagesBlock images={
                    ['/msv/1.jpeg', '/msv/2.jpeg', '/msv/3.jpeg', '/msv/4.jpeg']
                } alt='Установка и монтаж систем вентиляции'/>
            </Container>
        )
    }
}

export default PageLayout(VentilationInstall);
