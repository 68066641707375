import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"

class ConditionerService extends PageComponent {

    src = "/images/service/remont.jpg"

    priceList = [
        {
            name: 'в зависимости от сложности',
            price: 'от 600 руб'
        },
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость ремонта кондиционера' list={this.priceList}/>
                <Consultation/>
                <Content>
                    <h2>Диагностика и ремонт кондиционеров</h2>
                    <p>
                        Современный быт нельзя представить без климатической техники. Летом в жару ей приходится
                        работать на износ без выключения, вследствие чего накапливается усталость механических частей,
                        пыль, насекомые и тополиный пух в магистрали. Автоматика и электрическая часть также имеют свой
                        ресурс. Сказываются неправильный монтаж и нарушения правил эксплуатации. В климатических
                        системах появляются неисправности, или начинают некорректно работать, что требует оперативного
                        ремонта.
                        В Москве работают многочисленные сервисы по ремонту кондиционеров, предлагающие свои услуги.
                        Далеко не все они способны установить настоящую причину сбоев, вернув работу кондиционера в
                        штатный режим. Ремонт кондиционеров нужно поручать только профессионалам своего дела, а не тем
                        исполнителям, которые под видом устранения неполадок занимаются предоставлением второстепенных и
                        навязанных услуг.
                    </p>
                    <h3>Что такое диагностика?</h3>
                    <p>
                        Ремонту кондиционеров предшествует их диагностика. Проводится она всегда: и во время планового
                        обслуживания, и при отклонении работы агрегата от нормы. В зависимости от момента проведения она
                        преследует разные цели:
                    </p>
                    <ul>
                        <li>при плановом обслуживании – определить условия, способные повлечь поломки в будущем;
                        </li>
                        <li>при ненормальной работе техники – выявить причины уже возникших проблем.
                        </li>
                    </ul>
                    <p>
                        В пределах этой статьи нас интересует второй вид мероприятия, который состоит из:
                    </p>
                    <ul>
                        <li>визуального осмотра всех узлов системы</li>
                        <li>тестового пуска кондиционера, проверки работы всех его режимов</li>
                        <li>проверки давления в системе на предмет утечки фреона</li>
                        <li>проверки состояния дренажной системы, фильтров</li>
                        <li>проверки отдельных узлов и деталей: теплового насоса, испарителей, компрессора,
                            вентиляторов, датчиков управления, терморегулятора и пр.
                        </li>
                        <li>измерения показателей тока в сети</li>
                        <li>проверки электроцепи кондиционера, электрической платы</li>
                        <li>проверки характеристик исходящего потока воздуха и т.д</li>
                    </ul>
                    <p>
                        При выявлении неполадок в работе климатической системы перед вызовом техника стоит попробовать
                        установить причину неисправности самостоятельно, обратившись к соответствующему разделу
                        инструкции. Случается, что кондиционер работает правильно, но в силу ряда причин изменились
                        настройки управления, что заметно не сразу. Если самостоятельная диагностика не позволяет
                        выяснить источник проблемы, требуется помощь профессионалов.
                    </p>
                    <h3>Что включает ремонт?</h3>
                    <p>
                        Ремонт кондиционеров проводится только после их диагностики. Нельзя доверять мастерам, которые
                        советуют заменить запчасть или поменять фреон «на всякий случай», когда настоящие причины сбоев
                        кроются не в этих факторах. Профессиональный ремонт кондиционеров может предполагать:
                    </p>
                    <ul>
                        <li>замену сломанных или изношенных узлов механической части</li>
                        <li>замену электрических агрегатов и автоматики</li>
                        <li>восстановление целостности дренажной системы</li>
                        <li>внеплановое обслуживание. Часто причиной нештатной работы является несвоевременное или
                            неполное обслуживание, и устранить проблему удается, почистив фильтры, дренаж или дозаправив
                            фреон
                        </li>
                    </ul>
                    <p>
                        После проведения ремонта требуется проверка работы агрегата.
                    </p>

                    <h3>Куда обратиться за диагностикой и ремонтом кондиционеров?</h3>
                    <p>
                        Наша компания оказывает полный спектр подобных услуг, обратиться за которыми можно в любое время
                        суток, оставив заявку на нашем сайте или послав письмо по электронной почте. В рабочее время
                        можно сделать заказ по телефону.
                    </p>
                    <p>
                        Нашими конкурентными преимуществами являются:
                    </p>
                    <ul>
                        <li>ремонт всех видов климатических систем промышленного и бытового назначения - в квартире,
                            частном доме и на производстве
                        </li>
                        <li>оперативный выезд на объект мастера или бригады (в зависимости от объема работы и сложности
                            объекта) в удобное для заказчика время
                        </li>
                        <li>гарантия качества</li>
                        <li>приемлемая стоимость</li>
                        <li>отсутствие навязанных услуг, согласование с заказчиком объемов ремонта на основании данных
                            диагностики
                        </li>
                    </ul>
                </Content>
            </Container>
        )
    }
}

export default PageLayout(ConditionerService);
