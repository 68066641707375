import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"
import ImagesBlock from "../../UI/ImagesBlock"

class SplitInstall extends PageComponent {

    src = "/images/service/montaz.jpg"

    priceList = [
        {
            name: 'в зависимости от оборудования',
            price: 'от 8000 руб'
        },
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость монтажа спилт систем' list={this.priceList}/>
                <div className="works__link">
                    <a href='#works'>Примеры наших работ&nbsp;<i className="fa fa-link fa-sm"/></a>
                </div>
                <Consultation/>
                <Content>
                    <h2>
                        Монтаж и ремонт сплит систем
                    </h2>
                    <p>
                        Бытовые кондиционеры бывают моноблочными (мобильными и оконными), и сплит-системными, т.е.
                        состоящими минимум из двух блоков: наружного и внутреннего. Последние пользуются наибольшей
                        популярностью в жилых помещениях, и если вы захотите приобрести кондиционер, то вам по умолчанию
                        предложат именно сплит-систему. Но у нее есть один недостаток. Если оконный и мобильный агрегат
                        при минимальных технических навыках можно установить самостоятельно, то установка сплит систем
                        требует привлечения специалистов.
                    </p>
                    <h3>
                        Как происходит установка?
                    </h3>
                    <p>
                        Монтаж настенных сплит-систем включает:
                    </p>
                    <ul>
                        <li>Установку на наружной части стены, парапете или на полу балкона внешнего блока
                            кондиционера
                        </li>
                        <li>Установку внутреннего блока в помещении</li>
                        <li>Бурение в стене отверстия для прокладки дренажной трубки</li>
                        <li>Сборку фреоновой магистрали и дренажной трубки</li>
                        <li>Опрессовку и вакуумирование системы</li>
                        <li>Контрольный запуск</li>
                    </ul>
                    <p>
                        Установка мульти-сплит системы происходит примерно так же, несмотря на больший объем работ.
                        Перед ним требуется выполнить проектировку, т.к. подключение нескольких внутренних блоков
                        сопряжено с созданием разветвленной магистрали. В этом случае важно успеть выполнить монтаж
                        магистралей на этапе капитального ремонта или реконструкции помещений, т.к. позже возможности
                        для скрытого монтажа магистрали будут сильно ограничены, и ее придется декорировать.
                    </p>
                    <h3>
                        Ремонт сплит-систем
                    </h3>
                    <p>
                        Любая техническая система требует обслуживания. И кондиционеры здесь не являются исключением. В
                        данном случае, двухблочное оборудование гораздо требовательнее к сервису, чем одноблочная
                        техника, и заниматься им должны только квалифицированные специалисты.
                    </p>
                    <p>Ремонт, как составная часть обслуживания, может предполагать:</p>
                    <ul>
                        <li>Диагностику неполадок</li>
                        <li>Визуальный осмотр на предмет механических повреждений</li>
                        <li>Выявление сопутствующих условий, повлиявших на поломку</li>
                        <li>Замену испорченных узлов, не подлежащих ремонту</li>
                        <li>Восстановление работоспособности технических узлов на месте или в условиях мастерской</li>
                        <li>Восстановление герметичности системы в случае протечек или снижения давления</li>
                        <li>Чистку кондиционера и магистрали от пылевых и биологических накоплений, удаление засоров
                        </li>
                        <li>Замену воздушных фильтров</li>
                        <li>Дозаправку фреоном</li>
                        <li>Пусконаладочные работы, проверку рабочих параметров</li>
                        <li>Установку рабочих настроек.</li>
                    </ul>
                    <p>
                        Многие исполнители подобных услуг сводят их содержание только к доливке фреона, чистке
                        внутреннего блока и несложному ремонту, который они способны выполнить в силу своей компетенции.
                        При этом они забывают о прочих, на первый взгляд, второстепенных составляющих сервиса, что плохо
                        сказывается на долговечности сплит-систем и их ремонтопригодности.
                    </p>
                    <h3>
                        Кто оказывает услуги по установке и обслуживанию сплит-систем в Москве?
                    </h3>
                    <p>
                        В Москве установку сплит системы, монтаж, обслуживание и ремонт любого кондиционирующего
                        оборудования, в т.ч. мультиблочного, можно поручить нашей компании. Если вы уже установили
                        бытовой кондиционер, то не стоит дожидаться, пока снизится циркуляция хладагента и перестанет
                        охлаждаться воздух в помещении. Минимальное необходимое обслуживание должно производиться раз в
                        год, даже если кондиционер не используется.
                    </p>
                    <p>
                        При этом мы устанавливаем самые прозрачные и экономически обоснованные расценки на монтаж,
                        ремонт и обслуживание сплит-систем. Мы можем сообщить их вам сразу, т.к. в большинстве случаев
                        бытовые сплит-системы устанавливаются в типовых квартирах, а в других случаях смету работ
                        несложно посчитать сразу.
                    </p>
                </Content>
                <ImagesBlock images={
                    ['/mss/1.jpeg', '/mss/2.jpeg', '/mss/3.jpeg', '/mss/4.jpeg']
                } alt='Монтаж и ремонт сплит систем'/>
            </Container>
        )
    }
}

export default PageLayout(SplitInstall);
