import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"

class ConditionerClean extends PageComponent {

    priceList = [
        {
            name: 'заправка',
            price: 'от 1750 руб'
        },
        {
            name: 'дозаправка',
            price: 'от 1000 руб'
        },
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость заправки кондиционера' list={this.priceList}/>
                <Consultation/>
                <Content>
                    <h2>Заправка и обслуживание кондиционеров</h2>
                    <p>
                        Каждая техническая система нуждается в специальном сервисе, направленном на поддержание в ее
                        работоспособности и профилактику неисправностей в будущем. Климатическая техника не является
                        исключением. Обслуживание кондиционеров – популярная услуга, оказывать которую за ваши деньги
                        готовы все, но не все могут гарантировать положительный результат. В Москве действуют тысячи
                        организаций и мастеров-частников, сводящих обслуживание к одной только заправке.
                        Здесь мы попытаемся определить, чем заправка кондиционера отличается от его обслуживания.
                    </p>

                    <h3>В чем разница?</h3>
                    <p>
                        Обслуживание – это комплекс услуг, который в случае с климатическим оборудованием включает:
                    </p>
                    <ul>
                        <li>проверку правильности работы в разных режимах</li>
                        <li>визуальный осмотр всех узлов системы, определение их технического состояния</li>
                        <li>механическую и антибактериальную очистку внутренних устройств, магистрали и полостей</li>
                        <li>диагностику работы всех систем: дренажа, механики, электроплаты, фильтров</li>
                        <li>замеры показателей тока в электросети</li>
                        <li>изучение условий работы кондиционера на объекте на предмет соответствия правилам
                            эксплуатации
                        </li>
                        <li>ремонт и замену отдельных запчастей, узлов и агрегатов</li>
                        <li>проверку управления (пульта) на работоспособность</li>
                        <li>добавление расходных материалов и рабочих тел, в т.ч. хладагента</li>
                        <li>тестовый запуск системы после обслуживания</li>
                        <li>настройку параметров работы системы под определенные задачи</li>
                        <li>формирование рекомендаций по использованию климатической техники</li>
                    </ul>
                    <p>
                        Фреон – это газ-хладагент, использующийся в холодильниках и климатической технике в качестве
                        источника холода. Заправка кондиционера фреоном производится во время его первой установки, а
                        далее – по мере надобности в зависимости от давления этого газа в системе. Падение мощности
                        кондиционера – одно и следствий утечки хладагента. Поэтому ремонт и обслуживание кондиционера
                        часто требуют заправки.
                        Таким образом, заправка – это только одна из составных частей обслуживания, которая при первой
                        установке кондиционера рассматривается в виде неотъемлемой составляющей монтажа технической
                        системы. Два этих понятия соотносятся как общее и целое, поэтому не стоит доверять тем службам,
                        которые под видом «сервиса» выполняют одну лишь дозаправку фреоном.
                    </p>

                    <h3>У кого заказать обслуживание и заправку кондиционера?</h3>
                    <p>
                        Вы не ошибетесь, если за этими услугами обратитесь к нам. В зависимости от вида обслуживания, мы
                        проводим гарантийный и постгарантийный сервис, цены на который сильно зависят от комплекса
                        порученных работ.
                        Гарантийный сервис, в т.ч. ремонт, обходится заказчику бесплатно, если он не нарушает условий
                        гарантии. При посгарантийном обслуживании заправка хладагента и обслуживание исправной
                        климатической техники стоит очень недорого. В случае поломок на стоимость сильно влияет цена
                        запчастей и сложность ремонта, в связи с чем приблизительно сказать, во сколько обходится
                        обслуживание, невозможно.
                    </p>

                    <h3>У кого можно заказать чистку кондиционеров в Москве?</h3>
                    <p>

                        Наша компания поможет избавить климатическую систему любой сложности и назначения от жира, пыли,
                        бактерий и прочих инородных накоплений, препятствующих нормальной работе и создающих угрозу
                        санитарному состоянию объекта. У нас можно недорого заказать очистку домашнего и промышленного
                        кондиционера, а также системы воздуховодов и вентиляции, в которую он интегрирован. При этом мы
                        гарантируем такие конкурентные преимущества:
                    </p>
                    <ul>
                        <li>отсутствие навязанных услуг</li>
                        <li>прозрачное и понятное ценообразование</li>
                        <li>работа с климатической техникой бытового, общественного и промышленного назначения</li>
                        <li>высокий профессионализм и компетенция персонала</li>
                        <li>большой опыт работы</li>
                        <li>гарантии качества</li>
                        <li>обходительное отношение к заказчику, всесторонний учет его пожеланий</li>
                    </ul>
                </Content>
            </Container>
        )
    }
}

export default PageLayout(ConditionerClean);
