import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"

class RefrigerationService extends PageComponent {

    priceList = [
        {
            name: 'в зависимости от сложности',
            price: 'от 1000 руб'
        },
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость ремонта холодильного оборудования' list={this.priceList}/>
                <Consultation/>
                <Content>
                    <h2>Монтаж и ремонт холодильного оборудования</h2>
                    <p>
                        Мало какая производственная сфера обходится без агрегатов, вырабатывающих холод. Они служат для
                        хранения сырья и готовой продукции, а также доведения их потребительских качеств до необходимой
                        кондиции. Пищевая промышленность, фармацевтическое производство, сельскохозяйственный комплекс,
                        исследовательские лаборатории, транспортно-логистический сектор, сфера услуг и торговля – только
                        некоторые отрасли, в которых повсеместно используются холодильные или морозильные камеры.
                    </p>
                    <p>
                        Установка нового холодильного оборудования востребована в случае сдачи в эксплуатацию
                        нововозведенных производственных комплексов, а также при их перепланировке и реконструкции.
                        Каждое новое предприятие или предприниматель, занимающийся оказанием определенных услуг
                        (например, в области общественного питания), должны сделать заказ на своей оснащение
                        материальной базы холодильной техникой, иначе он даже не получит лицензии.
                    </p>
                    <h3>Что включает в себя монтаж?</h3>
                    <p>
                        Установка нового оборудования начинается с разработки проекта, учитывающего специфику
                        производственной сферы, степени потребности в охлаждающей технике, пожеланий заказчика и
                        объективных условий. На этом этапе происходит подбор рабочих элементов холодильной техники:
                    </p>
                    <ul>
                        <li> Промышленных рефрижераторов, морозильных камер</li>
                        <li> Торговых холодильных витрин, шкафов</li>
                        <li> Бытовых холодильников</li>
                        <li> Агрегатов шоковой заморозки</li>
                        <li> Промышленных чиллеров и систем кондиционирования</li>
                        <li> Компрессорных станций</li>
                        <li> Автоматики, обеспечивающей управление.</li>
                    </ul>
                    <p>
                        Далее производится выравнивание поверхности пола: холодильная аппаратура очень требовательна в
                        этом отношении. Затем происходит сбор всех элементов системы в одну цепь с общим управлением,
                        подключение к инженерным сетям: электросети, канализации, водопроводу и вентиляции. Если их нет,
                        они монтируются согласно проекту. Устанавливаются и подключаются источники резервного питания,
                        монтируются замки, устройства контроля доступа и сигнализации. Проверяется теплоизоляция,
                        герметичность помещений и всех узлов системы.
                    </p>
                    <p>
                        Завершается процесс пуско-наладочными испытаниями и регулировкой работы холодильного
                        оборудования.
                    </p>
                    <h3>Ремонт холодильной техники</h3>
                    <p>
                        Бесперебойная работа холодильного оборудования – залог успешного производства и стабильного
                        дохода. Что будет, если морозильная камера мясокомбината выйдет из строя, а витрины продуктового
                        магазина не смогут в жару поддерживать необходимый температурный режим?
                    </p>
                    <p>
                        Существует масса производств, где последствия могут быть даже фатальными, ведь потеря
                        дорогостоящего продукта или сырья наносит материальный ущерб, но поломка камеры холодильника для
                        хранения партии противовирусных вакцин или донорских препаратов ставит под угрозу ни одну
                        человеческую жизнь.
                    </p>
                    <p>
                        Нужны ли другие обоснования для ремонта холодильного оборудования? Он представляет собой вид
                        гарантийного или постгарантийного обслуживания, направленный на устранение возникших неполадок и
                        восстановление работоспособности технических систем.
                    </p>
                    <h3>Кто оказывает подобные услуги?</h3>
                    <p>
                        И монтаж, и ремонт холодильного оборудования, выполняются нашей компанией независимо от
                        сложности объекта, объема работ и их характера. Любой наш техник с одинаковой готовностью
                        способен выполнить ремонт промышленных холодильников и торговых витрин для прохладительных
                        напитков, ларей для мороженого и генераторов льда. В нашем штате достаточно профессионалов для
                        выполнения любого объема работ, какие только могут быть связаны с холодильным и морозильным
                        оборудованием. Просим принять к сведению, что доверять в данном деле нужно только специалистам
                        узкого профиля. Штатные работники предприятий, умельцы-кустари и дилетанты, не видевшие в жизни
                        ничего кроме бытового холодильника, здесь вас не выручат.
                    </p>
                </Content>
            </Container>
        )
    }
}

export default PageLayout(RefrigerationService);
