import React from 'react';
import PageLayout from "../../Decorators/PageLayout";
import PageComponent from "../../Decorators/PageComponent";
import Container from "./Components/Contaner"
import Header from "./Components/Header"
import PriceList from "./Components/PriceList"
import Content from "./Components/Content"
import Consultation from "../../UI/Consultation"
import Breadcrumbs from "../../UI/Breadcrumbs"

class VentilationRepair extends PageComponent {

    priceList = [
        {
            name: 'до 2500м3',
            price: 'от 1000 руб'
        },
        {
            name: 'от 2500 до 5000м3',
            price: 'от 1500 руб'
        },
        {
            name: ' от 5000м3',
            price: 'дог.'
        },
    ];

    render() {
        return (
            <Container>
                <Header h1={this.h1}/>
                <Breadcrumbs title={this.h1}/>
                <PriceList header='Стоимость диагностики вентиляции' list={this.priceList}/>
                <Consultation/>
                <Content>
                    <h2>Ремонт и диагностика вентиляционных систем</h2>
                    <p>
                        Случается, что нормальная работа вентилирующей системы нарушена или невозможна по
                        неустановленным причинам. В таких ситуациях целесообразно провести полное обслуживание, но
                        чистка и дезинфекция ничего не дадут, если не установить причину неполадок и не устранить в
                        первую очередь ее. Самой серьезной частью обслуживания являются диагностика и ремонт вентиляции,
                        поскольку большая часть всех проблем кроется не в расплодившихся тараканах или накопившейся
                        пыли, а поломках оборудование вследствие:
                    </p>
                    <ul>
                        <li>окончания срока его эксплуатации</li>
                        <li>нерегулярного обслуживания</li>
                        <li>неправильного использования</li>
                        <li>неправильного проекта или монтажа</li>
                    </ul>
                    <p>
                        Бесполезно чистить воздуховоды, если сломан вентилятор, теплообменник или рекуператор. Поэтому
                        распространенной услугой является диагностика и ремонт систем вентиляции.
                    </p>

                    <h3>Что такое диагностика?</h3>
                    <p>
                        Диагностика – это одна из составляющих обслуживания, которая представляет собой:
                    </p>
                    <ul>
                        <li>оценку технического состояния системы</li>
                        <li>выявление неисправностей</li>
                        <li>установление рисков возможных неполадок</li>
                        <li>определение остаточного ресурса оборудования</li>
                    </ul>
                    <p>
                        Показателем для диагностики является не только выход всей вентиляционной системы из строя, но и
                        снижение ее мощности, пропускной способности и прочих технических характеристик.
                    </p>
                    <h3>Что включает ремонт?</h3>
                    <p>Ремонт – это комплекс мероприятий по восстановлению работоспособности вентиляционных систем,
                        включающий:</p>
                    <ul>
                        <li>замену запчастей и расходных материалов (ремней, подшипников, масла, проводки)</li>
                        <li>замену поврежденных внешних частей конструкций: стоек, кронштейнов, корпусов, станин и
                            прочих элементов
                        </li>
                        <li>замену вышедших из строя узлов и агрегатов целиком</li>
                        <li>изменение проекта вентиляции, если причины выхода ее из строя были обусловлены неправильным
                            проектом или монтажом
                        </li>
                        <li>проверку работы вентиляционной системы после устранения неполадо</li>
                    </ul>
                    <p>Ремонт – это составная часть обслуживания, которая производится только по факту выявления
                        поломок. </p>

                    <h3>Наши услуги</h3>
                    <p>
                        Наша компания предоставляет весь спектр услуг по диагностике и ремонту приточно-вытяжной
                        вентиляции, в том числе и в случаях, когда неисправность или некорректная работа обусловлены
                        изначально неправильным монтажом или проектом.
                    </p>
                    <p>
                        Независимо от объекта, мы способны починить вентилирующее бытовое и промышленное оборудование.
                        Общественные здания, частные жилые дома, квартиры и апартаменты – тоже зона нашей
                        ответственности. Наши неоспоримые достоинства это:
                    </p>
                    <ul>
                        <li>профессиональный персонал</li>
                        <li>ответственность и гарантии качества</li>
                        <li>обходительное отношение к заказчику</li>
                        <li>понятное и обоснованное ценообразование</li>
                        <li>оперативное реагирование на вызов</li>
                        <li>подробнейшая и объективная консультация</li>
                        <li>гарантированное устранение неисправностей в самые короткие сроки</li>
                        <li>предоставление услуги в удобное для заказчика время</li>
                        <li>гарантия на все виды произведенных работ</li>
                    </ul>
                    <p>
                        Сколько стоит качественная диагностика и ремонт системы вентиляции зависит от ее сложности.
                        Единой цены здесь не существует, и расчет стоимости осуществляется индивидуально для каждого
                        отдельного объекта.
                    </p>
                </Content>
            </Container>
        )
    }
}

export default PageLayout(VentilationRepair);
